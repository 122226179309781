import { AuthenticatedTemplate } from "@azure/msal-react";
import {
  Text,
  Flex,
  RadioGroup,
  VStack,
  Radio,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OutlineBox } from "../components/outline-box";
import { HeaderText } from "../components/header-text";
import { KeyValuePair } from "../components/key-value-pair";
import { useTranslation } from "react-i18next";
import { useCrud } from "../hooks/crud-hook";
import { HeaderWithText } from "../components/header-with-text";
import { useAuthContext } from "../context/auth-context";
import { UseReportedObservation } from "../components/use-reported-observation";
import { useCreateWhistle } from "../hooks/whistle-form-hook";
import { useMutation } from "@tanstack/react-query";
import { MainContentContainer } from "../components/main-content-container";
import { LargeButton } from "../components/large-button";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFDocument } from "../components/pdf-document";

export const ManageReportPage: React.FC = () => {
  const { reportId } = useParams();
  const { t } = useTranslation();
  const { reports, updateWhistle, deleteWhistle } = useCrud();
  const auth = useAuthContext();
  const { updateFormData, setForm, form } = useCreateWhistle();
  const navigate = useNavigate();

  const [useReportObservation, setUseReportObservation] = useState(true);
  const [useWhereDidItHappen, setUseWhereDidItHappen] = useState(true);
  const [useWhoWasInvolved, setUseWhoWasInvolved] = useState(true);
  const [useThisHappend, setUseThisHappend] = useState(true);
  const [useActions, setUseActions] = useState(true);
  const [useAdditionalInformation, setUseAdditionalInformation] =
    useState(true);

  const report = reports.find((x) => x.ReportId === reportId);

  const { mutate } = useMutation({
    mutationFn: updateWhistle,
    onSuccess: (data) => {
      navigate("/admin");
    },
    onError: () => {
      alert("there was an error");
    },
  });

  const onSubmit = (archive: boolean) => {
    if (archive) {
      form.Resolved = new Date().toISOString();
    }
    form.Started = new Date().toISOString();
    mutate(form);
  };

  const onReopen = () => {
    form.Resolved = "";
    mutate(form);
  };

  const onDelete = () => {
    deleteWhistle(form)
      .then(() => navigate("/admin"))
      .catch((exception) => alert(exception));
  };

  useEffect(() => {
    if (report) setForm(report);
  }, [report]);

  const shouldBeContacted =
    report?.ObservationContactInformant !== undefined
      ? report?.ObservationContactInformant
      : false;

  return (
    <>
      {auth?.account && (
        <MainContentContainer>
          <OutlineBox>
            <HeaderText
              marginBottom="1rem"
              text={t("manageReportPage.details")}
              size="medium"
            />
            <KeyValuePair
              keyValue={t("manageReportPage.reported")}
              value={
                new Date(form.Reported).toLocaleDateString() +
                " " +
                new Date(form.Reported).toLocaleTimeString()
              }
            />
            <KeyValuePair
              keyValue={t("manageReportPage.started")}
              value={
                new Date(form.Started).toLocaleDateString() +
                " " +
                new Date(form.Started).toLocaleTimeString()
              }
            />
            <KeyValuePair
              keyValue={t("manageReportPage.resolved")}
              value={
                new Date(form.Resolved).toLocaleDateString() +
                " " +
                new Date(form.Resolved).toLocaleTimeString()
              }
            />
          </OutlineBox>
          <OutlineBox>
            <HeaderText
              marginBottom="1rem"
              text={t("manageReportPage.contactDetails")}
              size="medium"
            />
            {!shouldBeContacted && (
              <HeaderWithText
                header={t("manageReportPage.shouldRemainAnonymous")}
                text={t("manageReportPage.pleaseRemoveDetails")}
              />
            )}
            {shouldBeContacted && (
              <HeaderWithText
                header={t("manageReportPage.shouldBeContacted")}
                text={form?.ObservationContact}
              />
            )}
          </OutlineBox>
          <OutlineBox>
            <HeaderWithText
              headerSize="1.125rem"
              header={t("manageReportPage.serviceErrorDescription")}
              text={t("manageReportPage.serviceErrorInfo")}
            />
            <HeaderWithText
              header={t("manageReportPage.whenDidItHappen")}
              text={form?.ObservationOccurance}
            />
            {form.ObservationOccuranceEdited && (
              <HeaderWithText
                isHighlighted
                header={t("manageReportPage.updatedServiceError")}
                text={form?.ObservationOccuranceEdited}
              />
            )}

            {!form.Resolved && (
              <UseReportedObservation
                setState={setUseReportObservation}
                currentState={useReportObservation}
                reportPropertyToUpdate={"ObservationOccuranceEdited"}
                updateFormCallback={updateFormData}
              />
            )}
            <HeaderWithText
              header={t("manageReportPage.whereDidItHappen")}
              text={form?.ObservationWhereDidItHappend}
            />
            {form.ObservationWhereDidItHappendEdited && (
              <HeaderWithText
                isHighlighted
                header={t("manageReportPage.updatedServiceError")}
                text={form?.ObservationWhereDidItHappendEdited}
              />
            )}
            {!form.Resolved && (
              <UseReportedObservation
                setState={setUseWhereDidItHappen}
                currentState={useWhereDidItHappen}
                reportPropertyToUpdate={"ObservationWhereDidItHappendEdited"}
                updateFormCallback={updateFormData}
              />
            )}

            <HeaderWithText
              header={t("manageReportPage.whoWasInvolved")}
              text={form?.ObservationInvolved}
            />
            {form.ObservationInvolvedEdited && (
              <HeaderWithText
                isHighlighted
                header={t("manageReportPage.updatedServiceError")}
                text={form?.ObservationInvolvedEdited}
              />
            )}
            {!form.Resolved && (
              <UseReportedObservation
                setState={setUseWhoWasInvolved}
                currentState={useWhoWasInvolved}
                reportPropertyToUpdate={"ObservationInvolvedEdited"}
                updateFormCallback={updateFormData}
              />
            )}
            <HeaderWithText
              header={t("manageReportPage.thisHappened")}
              text={form?.ObservationWhatHappend}
            />
            {form.ObservationWhatHappendEdited && (
              <HeaderWithText
                isHighlighted
                header={t("manageReportPage.updatedServiceError")}
                text={form?.ObservationWhatHappendEdited}
              />
            )}
            {!form.Resolved && (
              <UseReportedObservation
                setState={setUseThisHappend}
                currentState={useThisHappend}
                reportPropertyToUpdate={"ObservationWhatHappendEdited"}
                updateFormCallback={updateFormData}
              />
            )}
            <HeaderWithText
              header={t("manageReportPage.anyActions")}
              text={form?.ObservationActions}
            />
            {form.ObservationActionsEdited && (
              <HeaderWithText
                isHighlighted
                header={t("manageReportPage.updatedServiceError")}
                text={form?.ObservationActionsEdited}
              />
            )}
            {!form.Resolved && (
              <UseReportedObservation
                setState={setUseActions}
                currentState={useActions}
                reportPropertyToUpdate={"ObservationActionsEdited"}
                updateFormCallback={updateFormData}
              />
            )}
            <HeaderWithText
              header={t("manageReportPage.additionalInformation")}
              text={form?.ObservationInformation}
            />
            {form.ObservationInformationEdited && (
              <HeaderWithText
                isHighlighted
                header={t("manageReportPage.updatedServiceError")}
                text={form?.ObservationInformationEdited}
              />
            )}
            {!form.Resolved && (
              <UseReportedObservation
                setState={setUseAdditionalInformation}
                currentState={useAdditionalInformation}
                reportPropertyToUpdate={"ObservationInformationEdited"}
                updateFormCallback={updateFormData}
              />
            )}
          </OutlineBox>
          <HeaderText
            text={t("manageReportPage.resolveObservation")}
            size="large"
          />
          <OutlineBox>
            <HeaderText text="Actions" size="medium" />

            {!form.Resolved && (
              <>
                <HeaderWithText
                  header={"Actions that will be taken based on the report"}
                  text={
                    "Here you can define what actions that will be taken based on the report. You can get back and archive the report later when the actions have been implemented."
                  }
                />
                <Textarea
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    updateFormData(
                      "ObservationActionsToBeTaken",
                      e.target.value
                    )
                  }
                />
              </>
            )}
            {form.Resolved && (
              <HeaderWithText
                header={t("manageReportPage.actionsBasedOnTheReport")}
                text={form.ObservationActionsToBeTaken}
              />
            )}

            {form.Resolved && (
              <HeaderWithText
                header={t("manageReportPage.responsibleForActions")}
                text={form.ObservationResponsibleForActions}
              />
            )}

            {!form.Resolved && (
              <>
                <HeaderWithText
                  header={"Who is responsible for the actions"}
                  text={
                    "Here you can define who is responsible for each action that will be taken based on the report."
                  }
                />
                <Textarea
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    updateFormData(
                      "ObservationResponsibleForActions",
                      e.target.value
                    )
                  }
                />
              </>
            )}
            {!form.Resolved && (
              <>
                <LargeButton
                  onClick={() => onSubmit(false)}
                  label={t("manageReportPage.keepReportActive")}
                  border="2px solid var(--WL---Brand-1, #183661)"
                  textColor="wasalineBlue"
                />

                <LargeButton
                  label={t("manageReportPage.archiveReport")}
                  border="2px solid var(--WL---Brand-1, #183661)"
                  textColor="wasalineBlue"
                  onClick={() => onSubmit(true)}
                />
              </>
            )}
          </OutlineBox>
          <LargeButton
            bgColor="wasalineBlue"
            textColor="white"
            border="2px solid var(--WL---Brand-1, #183661)"
          >
            <PDFDownloadLink
              document={<PDFDocument report={form} />}
              fileName={`report-${report?.Reported}.pdf`}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {t("manageReportPage.print")}
            </PDFDownloadLink>
          </LargeButton>

          {form.Resolved && (
            <>
              <LargeButton
                onClick={() => onReopen()}
                label={t("manageReportPage.reOpenReport")}
                color="white"
                textColor="wasalineBlue"
                border="2px solid var(--WL---Brand-1, #183661)"
              />
              <LargeButton
                label={t("manageReportPage.deleteReport")}
                color="white"
                textColor="wasalineBlue"
                border="2px solid var(--WL---Brand-1, #183661)"
                onClick={() => onDelete()}
              />
            </>
          )}
        </MainContentContainer>
      )}
    </>
  );
};
