import { Box, Button, Text, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { NormalText } from "./normal-text";
import { Link } from "react-router-dom";
import { LargeButton } from "./large-button";

export const ObservationContent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex
      flexGrow={1}
      flexDirection="column"
      borderRadius="20px"
      border="1px solid #DDE2E5"
    >
      <Text
        fontFamily="Poppins"
        padding="1rem"
        fontSize="1.5rem"
        fontWeight="400"
      >
        {t("howToReportObservationHeader")}
      </Text>
      <NormalText text={t("createObservationText")} padding="1rem" />

      <NormalText text={t("incognitoRecomendation")} padding="1rem" />
      <Box padding="1rem" display="flex" flexGrow={1}>
        <Link style={{ display: "flex", width: "100%" }} to={"/create-whistle"}>
          <LargeButton
            backgroundColor="wasalineBlue"
            label={t("createObservationButton")}
            color="white"
            flexGrow={1}
          />
        </Link>
      </Box>
    </Flex>
  );
};
