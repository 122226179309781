import { Badge, BadgeProps } from "@chakra-ui/react";
import React from "react";

interface IStatusBadgeProps extends BadgeProps {
  badgeText: string;
}

export const StatusBadge: React.FC<IStatusBadgeProps> = ({
  badgeText,
  ...props
}) => {
  return (
    <Badge
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap="10px"
      padding="8px 16px"
      borderRadius="24px"
      {...props}
    >
      {badgeText}
    </Badge>
  );
};
