import { Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface IMainContentContainer {
  children?: ReactNode;
}

export const MainContentContainer: React.FC<IMainContentContainer> = ({
  children,
}) => {
  return (
    <Flex
      margin={{ base: "4rem 1rem 1rem 1rem", lg: "4rem 0 1rem 0" }}
      width={{ base: "unset", lg: "800px" }}
      alignSelf="center"
      justifyContent="center"
      flexDirection="column"
      gap="3rem"
    >
      {children}
    </Flex>
  );
};
