import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

interface INormalTextProps extends TextProps {
  text: string;
}

export const NormalText: React.FC<INormalTextProps> = ({
  text,
  ...textProps
}) => {
  return (
    <Text
      fontSize="14px"
      fontStyle="normal"
      lineHeight="22px"
      wordBreak="break-word"
      fontFamily="Poppins"
      {...textProps}
    >
      {text}
    </Text>
  );
};
