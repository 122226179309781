import { Flex } from "@chakra-ui/react";
import React from "react";
import { HeaderText } from "../components/header-text";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MainContentContainer } from "../components/main-content-container";

export const ThankYouPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <MainContentContainer>
      <HeaderText
        textAlign="center"
        text={t("thankYouPage.header")}
        size={"large"}
      />
      <HeaderText
        textAlign="center"
        text={t("thankYouPage.infoText")}
        size={"medium"}
      />
      <HeaderText
        textAlign="center"
        text={t("thankYouPage.returnLink")}
        size={"medium"}
        textDecoration="underline"
        onClick={() => navigate("/")}
        cursor="pointer"
      />
    </MainContentContainer>
  );
};
