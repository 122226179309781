import { Box, Flex, FlexProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface IOutlineBox extends FlexProps {
  children: ReactNode;
}

export const OutlineBox: React.FC<IOutlineBox> = ({ children, ...props }) => {
  return (
    <Flex
      flexGrow={1}
      flexDirection="column"
      borderRadius="20px"
      border="1px solid #DDE2E5"
      {...props}
    >
      <Box display="flex" flexDirection="column" padding="1rem" gap="1rem">
        {children}
      </Box>
    </Flex>
  );
};
