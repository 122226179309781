import { Box, FormControl, Radio, RadioGroup, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { HeaderText } from "./header-text";
import { NormalText } from "./normal-text";
import { FormItem } from "./form-item";
import { useTranslation } from "react-i18next";

interface IFormContactContainerProps {
  updateFormCallback: (propertyName: string, value: any) => void;
}

export const FormContactContainer: React.FC<IFormContactContainerProps> = ({
  updateFormCallback,
}) => {
  const { t } = useTranslation();
  const [contactChecked, setContactChecked] = useState(false);

  const handleRadioChange = (value: any) => {
    setContactChecked(value === "contact");
    updateFormCallback("ObservationContactInformant", value === "contact");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      padding="1rem"
      borderRadius="20px"
      border="1px solid #DDE2E5"
      gap="1rem"
    >
      <HeaderText size="medium" text={t("form.contactDetails")} />
      <NormalText text={t("form.contactInfo")} />
      <FormControl as="fieldset">
        <RadioGroup defaultValue="anonymous" onChange={handleRadioChange}>
          <VStack align="flex-start">
            <Radio value="anonymous">{t("form.radioButtonAnonymous")}</Radio>
            <Radio value="contact">{t("form.radioButtonContact")}</Radio>
          </VStack>
        </RadioGroup>
      </FormControl>
      <FormItem
        label={t("form.contactDetails")}
        name="ObservationContact"
        helperText={t("form.disclosureInfo")}
        updateFormDataCallback={updateFormCallback}
        isRequired={contactChecked}
      />
    </Box>
  );
};
