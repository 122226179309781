import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { NormalText } from "./normal-text";
import { useTranslation } from "react-i18next";
import { HeaderText } from "./header-text";

export const ObservationTextArea: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap="1rem">
      <HeaderText text={t("whatIsItHeader")} size="medium" />
      <NormalText text={t("whatIsItText")} />
      <HeaderText text={t("purposeHeader")} size="medium" />
      <NormalText text={t("purposeText")} />
      <NormalText text={t("purposeConsistsOfText")} />
    </Flex>
  );
};
