import { UnauthenticatedTemplate } from "@azure/msal-react";
import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { ObservationContainer } from "../components/observation-container";
import { MainContentContainer } from "../components/main-content-container";

export const WhistleblowerPage: React.FC = () => {
  return (
    <UnauthenticatedTemplate>
      <MainContentContainer>
        <ObservationContainer />
      </MainContentContainer>
    </UnauthenticatedTemplate>
  );
};
