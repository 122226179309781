import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { HeaderText } from "./header-text";

interface IKeyValuePairProps {
  keyValue: string;
  value: string;
}

export const KeyValuePair: React.FC<IKeyValuePairProps> = ({
  keyValue,
  value,
}) => {
  return (
    <Flex flexDirection="row" gap="1rem" alignItems="center">
      <HeaderText text={keyValue + ":"} size="small" />
      <Text>{value}</Text>
    </Flex>
  );
};
