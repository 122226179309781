import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ObservationContent } from "./observation-content";
import { ObservationTextArea } from "./observation-text-area";
import { ObservationList } from "./observation-list";
import { NormalText } from "./normal-text";
import { HeaderText } from "./header-text";
import { LargeButton } from "./large-button";

interface IObservationContainerProps {}

export const ObservationContainer: React.FC<
  IObservationContainerProps
> = () => {
  const { t } = useTranslation();
  const downloadPrivacyPolicy = () => {
    const pdfUrl = "/WhistleblowingPrivacypolicy.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "WhistleblowingPrivacypolicy.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Flex
      flexGrow={1}
      flexDirection="column"
      gap="2rem"
      justifyContent="flex-start"
      alignSelf="center"
      margin={{ base: "1rem", lg: "unset" }}
    >
      <ObservationContent />
      <ObservationTextArea />
      <ObservationList />
      <NormalText text={t("reportsMustBeMadeText")} />
      <LargeButton
        onClick={downloadPrivacyPolicy}
        backgroundColor="wasalineBlue"
        color="white"
        label={t("downloadPrivacyPolicy")}
      />
    </Flex>
  );
};
