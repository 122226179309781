import React from "react";
import "./App.css";
import { AppRoutes } from "./routes/app-routes";
import { PageHeader } from "./components/page-header";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./context/auth-context";

interface IAppProps {}

export const App: React.FC<IAppProps> = () => {
  const queryClient = new QueryClient();
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <PageHeader />
            <AppRoutes />
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    </React.Fragment>
  );
};
