import { Select, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface IMobileLanguageSelectionProps {
  handleLanguageChange: (lang: string) => void;
  selectedLanguage: string;
}

export const MobileLanguageSelection: React.FC<
  IMobileLanguageSelectionProps
> = ({ handleLanguageChange, selectedLanguage }) => {
  const { t } = useTranslation();

  const languages = [
    t("language.english"),
    t("language.swedish"),
    t("language.finnish"),
  ];

  return (
    <>
      <Select
        onChange={(event) => handleLanguageChange(event.target.value)}
        display="flex"
        color="white"
        variant="unstyled"
        value={selectedLanguage}
      >
        {languages.map((item, index) => (
          <option
            key={index}
            value={item}
            style={{
              color: "black",
              display: "flex",
              justifyContent: "center",
              borderRadius: "0",
              fontFamily: "Poppins",
            }}
          >
            {item}
          </option>
        ))}
      </Select>
    </>
  );
};
