import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import msalInstance from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { ChakraProvider } from "@chakra-ui/react";
import "./data/translation/i18n";
import { defaultTheme } from "./theme/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ChakraProvider theme={defaultTheme}>
        <App />
      </ChakraProvider>
    </MsalProvider>
  </React.StrictMode>
);
