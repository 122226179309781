import { Box, Flex, ListItem, OrderedList, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { HeaderText } from "./header-text";

export const ObservationList: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex flexGrow={1} flexDirection="column" background="turquoise">
      <HeaderText
        padding="0.5rem 0 0 0.5rem"
        text={t("followingActs")}
        size="small"
      />
      <OrderedList padding="2rem">
        <ListItem>{t("observationListItem1")}</ListItem>
        <ListItem>{t("observationListItem2")}</ListItem>
        <ListItem>{t("observationListItem3")}</ListItem>
        <ListItem>{t("observationListItem4")}</ListItem>
        <ListItem>{t("observationListItem5")}</ListItem>
        <ListItem>{t("observationListItem6")}</ListItem>
        <ListItem>{t("observationListItem7")}</ListItem>
        <ListItem>{t("observationListItem8")}</ListItem>
        <ListItem>{t("observationListItem9")}</ListItem>
      </OrderedList>
    </Flex>
  );
};
