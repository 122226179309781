import { useState } from "react";
import { ReportModel } from "../data/models/report-model";

export const useCreateWhistle = () => {
  const [form, setForm] = useState<ReportModel>({} as ReportModel);

  const updateFormData = (propertyName: string, value: any) => {
    const updatedForm = { ...form, [propertyName]: value };
    setForm(updatedForm);
  };

  return { form, setForm, updateFormData };
};
