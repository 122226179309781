import {
  Badge,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HeaderText } from "./header-text";
import { ReportModel } from "../data/models/report-model";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { KeyValuePair } from "./key-value-pair";
import { OutlineBox } from "./outline-box";
import { StatusBadge } from "./status-badge";

interface IReportListItemProps {
  report: ReportModel;
}

export const ReportListItem: React.FC<IReportListItemProps> = ({ report }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <OutlineBox>
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        gap={{ base: "1rem", lg: "unset" }}
        justifyContent="space-between"
      >
        <Flex gap="1rem" flexDirection="column" alignItems="flex-start">
          <HeaderText
            text={report.ObservationWhatHappend}
            size={"medium"}
            fontWeight={600}
          />
          <KeyValuePair
            keyValue={t("adminPage.reportedText")}
            value={
              new Date(report.Reported).toLocaleDateString() +
              " " +
              new Date(report.Reported).toLocaleTimeString()
            }
          />
          {!report.Started && (
            <StatusBadge
              background="blueTBackground"
              border="blueBorder"
              color="textColorBlue"
              badgeText={t("adminPage.isNewReportText")}
            />
          )}
          {report.Started && !report.Resolved && (
            <StatusBadge
              background="yellowTBackground"
              border="yellowBorder"
              color="textColorYellow"
              badgeText={t("adminPage.activeReport")}
            />
          )}
        </Flex>
        <Flex>
          <Button
            alignSelf={{ base: "flex-end", lg: "unset" }}
            onClick={() => navigate(`/manage-report/${report.ReportId}`)}
          >
            {t("adminPage.manageReport")}
          </Button>
        </Flex>
      </Flex>
    </OutlineBox>
  );
};
