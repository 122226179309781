import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface ILanguageSelectionProps {
  handleLanguageChange: (lang: string) => void;
  selectedLanguage: string;
}

export const LanguageSelection: React.FC<ILanguageSelectionProps> = ({
  handleLanguageChange,
  selectedLanguage,
}) => {
  const { t } = useTranslation();

  const languages = [
    t("language.english"),
    t("language.swedish"),
    t("language.finnish"),
  ];

  return (
    <>
      {languages.map((item, index) => (
        <Box
          as="button"
          key={item}
          fontSize="small"
          px="1rem"
          borderLeft={index > 0 ? "2px solid white" : ""}
          color="white"
          textDecoration={item === selectedLanguage ? "underline" : "none"}
          _hover={{
            textDecoration: "underline",
          }}
          onClick={() => handleLanguageChange(item)}
          fontFamily="Poppins"
        >
          {item}
        </Box>
      ))}
    </>
  );
};
