import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translations_en from "./en.json";
import translations_fi from "./fi.json";
import translations_swe from "./swe.json";

const resources = {
  english: {
    translation: translations_en,
  },
  finnish: {
    translation: translations_fi,
  },
  swedish: {
    translation: translations_swe,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "english",
});

export default i18next;
