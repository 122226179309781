import { Box, Flex, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FormContainer } from "../components/form-container";
import { FormContactContainer } from "../components/form-contact-container";
import { HeaderText } from "../components/header-text";
import { Link, useNavigate } from "react-router-dom";
import { useCreateWhistle } from "../hooks/whistle-form-hook";
import { useCrud } from "../hooks/crud-hook";
import { useMutation } from "@tanstack/react-query";
import { MainContentContainer } from "../components/main-content-container";
import { LargeButton } from "../components/large-button";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

export const CreateWhistlePage: React.FC = () => {
  const [enableSendObservation, setEnableSendObservation] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(false);

  const { updateFormData, form } = useCreateWhistle();
  const { postWhistle } = useCrud();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate, isPending } = useMutation({
    mutationFn: postWhistle,
    onSuccess: (data) => {
      navigate("/thank-you");
    },
    onError: () => {
      alert("there was an error");
    },
  });

  const onSubmit = () => {
    mutate(form);
  };

  const captchaStateChange = (value: any) => {
    if (value) setCaptchaValue(true);
    else setCaptchaValue(false);
  };

  useEffect(() => {
    if (
      !form.ObservationOccurance ||
      !form.ObservationWhereDidItHappend ||
      !form.ObservationWhatHappend ||
      (form.ObservationContactInformant && !form.ObservationContact)
    )
      setEnableSendObservation(false);
    else setEnableSendObservation(true);
  }, [form]);

  return (
    <MainContentContainer>
      {isPending && <h1>loading</h1>}
      <HeaderText size="large" text="Report new observation" />
      <FormContainer updateFormCallback={updateFormData} />
      <FormContactContainer updateFormCallback={updateFormData} />
      <ReCAPTCHA
        sitekey="6LdD3g8pAAAAAMjzXBeh9ULXxsszTOTWzd35bLC4"
        onChange={captchaStateChange}
      />
      <Box display="flex" flexGrow={1} marginBottom="1rem">
        <Link style={{ display: "flex", width: "100%" }} to={"/create-whistle"}>
          <LargeButton
            isDisabled={!enableSendObservation || !captchaValue}
            label={t("sendObservation")}
            onClick={onSubmit}
            backgroundColor="wasalineBlue"
            color="white"
            flexGrow={1}
          />
        </Link>
      </Box>
    </MainContentContainer>
  );
};
