import React, { useEffect, useState } from "react";
import { ReportModel } from "../data/models/report-model";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "../context/auth-context";

export const useCrud = () => {
  const [reports, setReports] = useState<ReportModel[]>([{} as ReportModel]);
  const auth = useAuthContext();

  const postWhistle = async (data: ReportModel) => {
    const { data: response } = await axios.post(
      "https://wasaline-whistleblower.azurewebsites.net/api/Create",
      data
    );
    return response.data;
  };

  const updateWhistle = async (data: ReportModel) => {
    const token = await auth?.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data: response } = await axios.post(
      "https://wasaline-whistleblower.azurewebsites.net/api/Update",
      data,
      { headers }
    );
    return response.data;
  };

  const deleteWhistle = async (data: ReportModel) => {
    const token = await auth?.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data: response } = await axios.post(
      "https://wasaline-whistleblower.azurewebsites.net/api/Delete",
      data,
      { headers }
    );
    return response.data;
  };

  const getReports = async () => {
    const token = await auth?.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios
      .get("https://wasaline-whistleblower.azurewebsites.net/api/Get", {
        headers,
      })
      .then((res) => {
        return res.data;
      });
  };

  const { isPending, error, data } = useQuery({
    queryKey: ["repoData"],
    queryFn: getReports,
  });

  useEffect(() => {
    if (data) setReports(data);
  }, [data]);

  return {
    postWhistle,
    updateWhistle,
    deleteWhistle,
    reports,
    isPending,
    error,
  };
};
