import { Button, ButtonProps, Text, background } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ILargeButtonProps extends ButtonProps {
  label?: string;
  children?: ReactNode;
}

export const LargeButton: React.FC<ILargeButtonProps> = ({
  label,
  children,
  ...props
}) => {
  return (
    <Button
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignSelf="stretch"
      borderRadius="12px"
      padding="2.063rem 1rem"
      fontSize="1.125rem"
      fontWeight="600"
      lineHeight="normal"
      textAlign="center"
      fontFamily="Poppins"
      _hover={{}}
      gap="10px"
      {...props}
    >
      {label && label}
      {children && children}
    </Button>
  );
};
