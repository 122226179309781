import { Input, Radio, RadioGroup, Textarea, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface IUserReportedObservationProps {
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  currentState: boolean;
  reportPropertyToUpdate: string;
  updateFormCallback: (propertyName: string, value: any) => void;
}

export const UseReportedObservation: React.FC<
  IUserReportedObservationProps
> = ({
  setState,
  currentState,
  reportPropertyToUpdate,
  updateFormCallback,
}) => {
  const handleRadioStateChange = (value: any) => {
    setState(value === "useReportObservation");
  };
  const { t } = useTranslation();

  return (
    <>
      <RadioGroup
        defaultValue="useReportObservation"
        onChange={handleRadioStateChange}
      >
        <VStack align="flex-start">
          <Radio value="useReportObservation">
            {t("manageReportPage.useReportedObservation")}
          </Radio>
          <Radio value="updateReportObservation">
            {t("manageReportPage.updateReportedObservation")}
          </Radio>
        </VStack>
      </RadioGroup>
      {!currentState && (
        <Textarea
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            updateFormCallback(reportPropertyToUpdate, e.target.value)
          }
        />
      )}
    </>
  );
};
