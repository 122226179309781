import React from "react";
import { Text, TypographyProps } from "@chakra-ui/react";

interface IHeaderWithTextProps {
  header: string;
  headerSize?: string;
  text?: string;
  isHighlighted?: boolean;
}

export const HeaderWithText: React.FC<IHeaderWithTextProps> = ({
  header,
  headerSize,
  text,
  isHighlighted,
}) => {
  return (
    <>
      <Text
        color={isHighlighted ? "textColorBlue" : "textColorBlack"}
        fontSize={headerSize ? headerSize : "1rem"}
        fontWeight="600"
        fontFamily="Poppins"
      >
        {header}
      </Text>
      <Text fontFamily="Poppins" fontSize="1rem" fontWeight="400">
        {text}
      </Text>
    </>
  );
};
