import { Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { ReportModel } from "../data/models/report-model";

interface IPDFDocumentProps {
  report: ReportModel;
}

export const PDFDocument: React.FC<IPDFDocumentProps> = ({ report }) => {
  const styles = StyleSheet.create({
    page: {},
    section: {
      textAlign: "left",
      margin: "24px",
      fontSize: "12px",
      padding: "24px",
      marginBottom: "30px",
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      textWrap: "wrap",
      padding: "5px",
    },
    text: {
      padding: "10px",
    },
    header: {
      marginBottom: "5px",
      fontWeight: "bold",
    },
  });

  return (
    <Document>
      <Page
        style={{
          display: "flex",
        }}
        size="A4"
      >
        <View style={styles.section}>
          <View style={styles.keyValue}>
            <Text style={styles.header}>Reported:</Text>
            <Text>
              {new Date(report.Reported).toLocaleDateString() +
                " " +
                new Date(report.Reported).toLocaleTimeString()}
            </Text>
          </View>

          <View style={styles.keyValue}>
            <Text style={styles.header}>Started:</Text>
            <Text>
              {" "}
              {new Date(report.Started).toLocaleDateString() +
                " " +
                new Date(report.Started).toLocaleTimeString()}
            </Text>
          </View>
          <View style={styles.keyValue}>
            <Text style={styles.header}>Resolved:</Text>
            <Text>
              {" "}
              {new Date(report.Resolved).toLocaleDateString() +
                " " +
                new Date(report.Resolved).toLocaleTimeString()}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.keyValue}>
            <Text style={styles.header}>When did the observation occur?:</Text>
            <Text>
              {report.ObservationOccuranceEdited
                ? report.ObservationOccuranceEdited
                : report.ObservationOccurance}
            </Text>
          </View>
          <View style={styles.keyValue}>
            <Text style={styles.header}>Where did it happen?:</Text>
            <Text>
              {report.ObservationWhereDidItHappendEdited
                ? report.ObservationWhereDidItHappendEdited
                : report.ObservationWhereDidItHappend}
            </Text>
          </View>
          <View style={styles.keyValue}>
            <Text style={styles.header}>Who was involved:</Text>
            <Text>
              {report.ObservationInvolvedEdited
                ? report.ObservationInvolvedEdited
                : report.ObservationInvolved}
            </Text>
          </View>
          <View style={styles.keyValue}>
            <Text style={styles.header}>This happened:</Text>
            <Text>
              {report.ObservationWhatHappendEdited
                ? report.ObservationWhatHappendEdited
                : report.ObservationWhatHappend}
            </Text>
          </View>
          <View style={styles.keyValue}>
            <Text style={styles.header}>Have you taken any other actions:</Text>
            <Text>
              {report.ObservationActionsEdited
                ? report.ObservationActionsEdited
                : report.ObservationActions}
            </Text>
          </View>
          <View style={styles.keyValue}>
            <Text style={styles.header}>Additional information:</Text>
            <Text>
              {report.ObservationInformationEdited
                ? report.ObservationInformationEdited
                : report.ObservationInformation}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.keyValue}>
            <Text style={styles.header}>Responsible for actions:</Text>
            <Text>{report.ObservationResponsibleForActions}</Text>
          </View>
          <View style={styles.keyValue}>
            <Text style={styles.header}>Actions to be taken:</Text>
            <Text style={styles.text}>
              {report.ObservationActionsToBeTaken}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
