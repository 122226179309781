import { extendTheme } from "@chakra-ui/react";

export const defaultTheme = extendTheme({
  colors: {
    buttonBlue: "#5570F1",
    pinkBackground: "#E8E8FB",
    wasalineBlue: "var(--WL---Brand-1, #183661)",
    textColorBalck: "var(--WL---Dark, #222020)",
    textColorBlue: "var(--WL---Brand-2, #4488A9)",
    textColorYellow: "#CBA320",
    wasalineLightBlue: "var(--WL---Brand-4, #B8E1E1)",
    turquoise: "var(--WL---Brand-4, #B8E1E1)",
    yellowBorder: "1px solid var(--WL---Brand-3, #F3D880)",
    blueBorder: "1px solid var(--WL---Light-grey, #E3E4E4)",
    yellowTBackground: "rgba(243, 216, 128, 0.20)",
    blueTBackground: "rgba(68, 136, 169, 0.10)",
  },
});
