import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";
import { ReportList } from "../components/report-list";
import { useCrud } from "../hooks/crud-hook";
import { ReportModel } from "../data/models/report-model";
import { useAuthContext } from "../context/auth-context";
import { useTranslation } from "react-i18next";
import { MainContentContainer } from "../components/main-content-container";

export const AdminPage: React.FC = () => {
  const { reports, isPending } = useCrud();
  const auth = useAuthContext();
  const { t } = useTranslation();

  const activeReports = reports.filter((item: ReportModel) => !item.Resolved);
  const archivedReports = reports.filter((item: ReportModel) => item.Resolved);

  return (
    <>
      {auth?.account && (
        <MainContentContainer>
          {isPending && <Spinner />}
          <ReportList
            reportListItems={activeReports}
            header={t("manageReportPage.activeReports")}
          />
          <ReportList
            reportListItems={archivedReports}
            header={t("manageReportPage.archivedReports")}
          />
        </MainContentContainer>
      )}
    </>
  );
};
