import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

interface IHeaderTextProps extends TextProps {
  text: string;
  size: "large" | "medium" | "small" | "xsmall";
}

export const HeaderText: React.FC<IHeaderTextProps> = ({
  text,
  size,
  ...props
}) => {
  const sizeToRem = (size: "large" | "medium" | "small" | "xsmall") => {
    switch (size) {
      case "large":
        return "2rem";
      case "medium":
        return "1.125rem";
      case "small":
        return "1rem";
      case "xsmall":
        return "0.5rem";
      default:
        return "1rem";
    }
  };

  return (
    <Text
      fontWeight="600"
      lineHeight="normal"
      fontStyle="normal"
      fontFamily="Poppins"
      fontSize={sizeToRem(size)}
      {...props}
    >
      {text}
    </Text>
  );
};
