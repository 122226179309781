import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";

export const useAuth = () => {
  const { instance } = useMsal();
  const storedAccount = localStorage.getItem("authAccount");
  const [token, setToken] = useState(localStorage.getItem("authToken") || "");
  const [account, setAccount] = useState<AccountInfo | undefined>(
    storedAccount ? JSON.parse(storedAccount) : undefined
  );
  const [tokenExpiration, setTokenExpiration] = useState<Date | null>(
    localStorage.getItem("tokenExpiresOn")
      ? new Date(localStorage.getItem("tokenExpiresOn") as string)
      : null
  );

  const getToken = async () => {
    if (tokenExpiration && tokenExpiration < new Date()) {
      const response = await instance
        .acquireTokenSilent({
          scopes: [""],
          account: account,
        })
        .then((res) => {
          setToken(res.idToken);
          setTokenExpiration(res.expiresOn);
          localStorage.setItem("authToken", res.idToken);
          localStorage.setItem(
            "tokenExpiresOn",
            res.expiresOn ? res.expiresOn.toISOString() : ""
          );
        });
    }
    return token;
  };

  useEffect(() => {
    instance.setActiveAccount(account ? account : null);
  }, [instance, account]);

  const handleLogin = async () => {
    instance
      .loginPopup()
      .then((res) => {
        setAccount(res.account);
        setToken(res.idToken);
        setTokenExpiration(res.expiresOn);
        localStorage.setItem("authToken", res.idToken);
        localStorage.setItem("authAccount", JSON.stringify(res.account));
        localStorage.setItem(
          "tokenExpiresOn",
          res.expiresOn ? res.expiresOn.toISOString() : ""
        );
      })
      .catch((exception) => console.log(exception));
  };

  const handleLogout = () => {
    localStorage.clear();
    instance
      .logout()
      .then(() => {
        setAccount(undefined);
        setToken("");
        localStorage.clear();
      })
      .catch((exception) => console.log(exception));
  };

  return { handleLogin, handleLogout, getToken, account };
};
