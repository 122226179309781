import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ReportModel } from "../data/models/report-model";

interface IFormItemProps extends FormControlProps {
  label: string;
  name: keyof ReportModel;
  helperText?: string;
  isRequired?: boolean;
  updateFormDataCallback: (propertyName: string, value: any) => void;
}

export const FormItem: React.FC<IFormItemProps> = ({
  label,
  helperText,
  name,
  updateFormDataCallback,
  isRequired,
  ...props
}) => {
  const [input, setInput] = useState("");
  const [wasTouched, setWasTouched] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    updateFormDataCallback(e.target.name, e.target.value);
  };

  const handleOnFocus = () => {
    setWasTouched(true);
  };

  const isError = isRequired && input === "";

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={isError && wasTouched}
      {...props}
    >
      <FormLabel fontFamily="Poppins">{label}</FormLabel>
      <Textarea onFocus={handleOnFocus} name={name} onChange={handleChange} />
      {FormHelperText && <FormHelperText>{helperText}</FormHelperText>}
      {isRequired && isError && wasTouched && (
        <FormErrorMessage>{label} is required.</FormErrorMessage>
      )}
    </FormControl>
  );
};
