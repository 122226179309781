import React, { ReactNode, createContext, useContext } from "react";
import { useAuth } from "../hooks/auth-hook";
import { AccountInfo } from "@azure/msal-browser";

interface IAuthContext {
  handleLogin: () => Promise<void>;
  handleLogout: () => void;
  getToken: () => Promise<string>;
  account: AccountInfo | undefined;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
