import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import React from "react";

const msalConfig: Configuration = {
  auth: {
    clientId: "f832ab80-1382-4a2f-b8a0-d77d1067b5b6",
    redirectUri: "https://whistle.wasaline.com/",
    authority:
      "https://login.microsoftonline.com/cff0ab2d-b932-43d8-919f-065ea5c745a1",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
