import React from "react";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { WhistleblowerPage } from "../pages/whistelblower-page";
import { AdminPage } from "../pages/admin-page";
import { CreateWhistlePage } from "../pages/create-whistle-page";
import { ThankYouPage } from "../pages/thank-you-page";
import { ManageReportPage } from "../pages/manage-report-page";

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<WhistleblowerPage />} path="/" />
      <Route element={<CreateWhistlePage />} path="/create-whistle" />
      <Route element={<AdminPage />} path="/admin" />
      <Route element={<ThankYouPage />} path="/thank-you" />
      <Route element={<ManageReportPage />} path="/manage-report/:reportId" />
    </Routes>
  );
};
