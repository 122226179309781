import { useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/auth-context";
import { LanguageSelection } from "./language-selection";
import { MobileLanguageSelection } from "./mobile-language-selection";

interface IPageHeaderProps {}

export const PageHeader: React.FC<IPageHeaderProps> = () => {
  const { t, i18n } = useTranslation();
  const auth = useAuthContext();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState(
    t("language.english").toLocaleLowerCase()
  );

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.toLocaleLowerCase());
  }, [selectedLanguage, i18n]);

  const handleLanguageChange = (lang: string) => {
    setSelectedLanguage(lang);
  };

  useEffect(() => {
    if (auth?.account) navigate("/admin");
    else navigate("/");
  }, [auth?.account]);

  const screenBreakpoint = useBreakpointValue({
    base: "mobile",
    lg: "fullscreen",
  });

  return (
    <Heading
      backgroundColor="wasalineBlue"
      boxShadow="0px 2px 8px 0px rgba(16, 24, 32, 0.05)"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        alignSelf="stretch"
        padding={{ base: "1rem 1.5rem", lg: "1rem 6.25rem" }}
      >
        {screenBreakpoint === "fullscreen" && (
          <Image
            style={{ width: "131px", height: "60px", cursor: "pointer" }}
            src="/images/wasaline.png"
            onClick={() => (auth?.account ? navigate("/admin") : navigate("/"))}
          />
        )}

        {screenBreakpoint === "mobile" && (
          <Image
            alignSelf="flex-start"
            style={{ width: "90px", height: "40px", cursor: "pointer" }}
            src="/images/wasaline.png"
            onClick={() => (auth?.account ? navigate("/admin") : navigate("/"))}
          />
        )}

        <Box display="flex" flexDirection="row" gap="1rem">
          {screenBreakpoint === "fullscreen" && (
            <LanguageSelection
              handleLanguageChange={handleLanguageChange}
              selectedLanguage={selectedLanguage}
            />
          )}
          {screenBreakpoint === "mobile" && (
            <MobileLanguageSelection
              handleLanguageChange={handleLanguageChange}
              selectedLanguage={selectedLanguage}
            />
          )}

          {auth?.account && (
            <Text
              color="wasalineLightBlue"
              fontWeight="600"
              fontSize="1rem"
              as="button"
              fontFamily="Poppins"
              onClick={auth.handleLogout}
              whiteSpace="nowrap"
            >
              {t("signOut")}
            </Text>
          )}
          {!auth?.account && (
            <Text
              color="wasalineLightBlue"
              fontWeight="600"
              fontSize="1rem"
              as="button"
              onClick={auth?.handleLogin}
              fontFamily="Poppins"
              whiteSpace="nowrap"
            >
              {t("signIn")}
            </Text>
          )}
        </Box>
      </Flex>
    </Heading>
  );
};
