import React from "react";
import { HeaderText } from "./header-text";
import { Flex } from "@chakra-ui/react";
import { ReportListItem } from "./report-list-item";
import { ReportModel } from "../data/models/report-model";

interface IReportListProps {
  reportListItems: ReportModel[];
  header: string;
}

export const ReportList: React.FC<IReportListProps> = ({
  reportListItems,
  header,
}) => {
  return (
    <Flex flexDirection="column" gap="1rem">
      <HeaderText text={header} size="large" />
      {reportListItems && (
        <Flex gap="0.5rem" flexDirection="column">
          {reportListItems.map((item: ReportModel, index: number) => {
            return <ReportListItem key={index} report={item} />;
          })}
        </Flex>
      )}
    </Flex>
  );
};
