import { Box } from "@chakra-ui/react";
import React from "react";
import { FormItem } from "./form-item";
import { useTranslation } from "react-i18next";

interface IFormContainerProps {
  updateFormCallback: (propertyName: string, value: any) => void;
}

export const FormContainer: React.FC<IFormContainerProps> = ({
  updateFormCallback,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      padding="1rem"
      borderRadius="20px"
      border="1px solid #DDE2E5"
      gap="1rem"
    >
      <FormItem
        isRequired
        name="ObservationOccurance"
        label={t("form.occurance")}
        updateFormDataCallback={updateFormCallback}
      />
      <FormItem
        isRequired
        name="ObservationWhereDidItHappend"
        label={t("form.where")}
        updateFormDataCallback={updateFormCallback}
      />
      <FormItem
        name="ObservationInvolved"
        label={t("form.involved")}
        updateFormDataCallback={updateFormCallback}
      />
      <FormItem
        isRequired
        name="ObservationWhatHappend"
        label={t("form.happend")}
        updateFormDataCallback={updateFormCallback}
      />
      <FormItem
        name="ObservationActions"
        label={t("form.actions")}
        updateFormDataCallback={updateFormCallback}
      />
      <FormItem
        name="ObservationInformation"
        label={t("form.additional")}
        updateFormDataCallback={updateFormCallback}
      />
    </Box>
  );
};
